.search-pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid #eaecef;
  align-items: baseline;
  font-size: 16px;
}

.search-pagination__spacer {
  flex: 1;
}

.search-pagination__button {
  border: 0;
  padding: 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #ec0016;
  }

  & svg {
    height: 1em;
    width: 1em;
  }
}

.search-pagination__button--current {
  color: #ec0016;
}

.search-pagination__button--previous svg {
  transform: rotate(180deg);
}
