.search-frequently-searched {
  margin-top: 20px;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 0.5em;

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.search-frequently-searched__label {
  display: block;
  white-space: nowrap;
}

.search-frequently-searched__tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.search-frequently-searched__tag {
  padding: 0.3em 0.7em 0.2em;
  background-color: #f0f3f5;
  border: none;
  border-radius: 15px;
  color: #878c96;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  line-height: 1;

  &:hover {
    background-color: #d7dce1;
    color: #666;
  }
}

.search-frequently-searched__tag--active {
  background-color: #ec0016 !important;
  color: #fff !important;
}
