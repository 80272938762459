.search-button {
  border: 0;
  padding: 0;
  line-height: 1;
  background: none;
  height: 1.1em;
  width: 1.1em;
  cursor: pointer;

  & > svg {
    height: 1.1em;
    width: 1.1em;
  }
}

.search-dialog {
  position: fixed;
  inset: 0;
  overflow: hidden;
  z-index: 60;
  background: #fff;
}

.search-dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #eee;
  z-index: 10;
  background: #fff;
  height: var(--header-height, 0);
}

.search-dialog__panel {
  margin-top: var(--header-height);
  height: 100vh;
  max-height: calc(100vh - var(--header-height, 0px));
  position: relative;
  scrollbar-gutter: stable both-edges;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  overflow-y: auto;
}

.search-dialog__content {
  max-width: 840px;
  width: 100%;
  height: 100%;
  margin: 30px auto;
}

.search-dialog__bottom-spacer {
  height: 60px;
}

.search-dialog__title {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.search-dialog__spinner {
  position: absolute;
  left: calc(50% - 30px);
  animation: searchspin 1s infinite linear;
  width: 60px;
  height: 60px;
  margin: 20px auto;
  margin-top: 20vh;
}

@keyframes searchspin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
