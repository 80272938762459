.search-results {
  margin-top: 30px;

  & > h2 {
    font-size: 28px;

    @media (min-width: 800px) {
      font-size: 34px;
    }
  }

  & mark {
    background-color: #ff0;
    border-radius: 6px;
  }
}

.search-results__tabs {
  border-bottom: 4px solid #f0f3f5;
  margin-bottom: 2em;
}

.search-results__tab {
  border: 0;
  border-bottom: 4px solid #f0f3f5;
  background-color: #fff;
  color: #878c96;
  padding: 6px 12px;
  margin-bottom: -4px;

  &.active,
  &:hover,
  &:active,
  &:focus {
    &:not([disabled]) {
      border-bottom-color: #ec0016;
      color: #000;
      cursor: pointer;
    }
  }
}

.search-not-found {
  margin-top: 30px;
  color: #c50014;
  font-size: 18px;
}

.search-results__items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.search-result {
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  & > * {
    margin: 0;
  }
}

.search-result__link {
  display: flex;
  align-items: baseline;
  gap: 0.5em;
  color: #ec0016;
  transition: color 0.3s ease-in-out;
  font-size: 18px;

  & svg {
    transition: transform 0.3s ease-in-out;
    font-size: 0.8em;
    width: 1em;
    height: 1em;
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
  }

  &:hover,
  &:focus {
    color: #c50014;

    & svg {
      transform: translateX(4px);
    }
  }
}

.search-result__breadcrumb {
  color: #afb4bb;
  font-size: 16px;
}

.search-result__tag {
  padding: 0.3em 0.7em 0.2em;
  background-color: #ec0016;
  border: none;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  line-height: 1;
  text-transform: uppercase;
}

.search-result__title {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.search-result__title h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.search-result__text {
  font-size: 18px;
  line-height: 1.7;
}
