.search-form {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  line-height: 1;
  box-shadow: inset 0 0 0 1px #afb4bb;

  &:focus-within {
    box-shadow: inset 0 0 0 2px #3c414b;
  }

  & button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 0;
  }

  & svg {
    width: 1em;
    height: 1em;
  }
}

.search-form__error {
  color: #c50014;
}

.search-form__label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.search-form__clear {
  color: #afb4bb;
  background: transparent;
  flex: 0 0 52px;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    color: #646973;
  }

  & svg {
    font-size: 17px;
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.search-form__submit {
  flex: 0 0 51px;
  background-color: #ec0016;
  transition: background-color 0.2s ease-in-out;
  color: #fff;
  font-size: 24px;

  &:hover {
    background-color: #c50014;
  }

  @media (min-width: 800px) {
    font-size: 30px;
    flex: 0 0 80px;
  }

  &.search-form__submit--disabled,
  &:disabled {
    background-color: #ec0016;
    cursor: not-allowed;
  }
}

.search-form__input {
  padding: 2px 16px 0;
  line-height: 49px;
  border: 0;
  flex: 1;
  font-size: 18px;
  background: transparent;
  width: 0;

  @media (min-width: 800px) {
    line-height: 59px;
  }

  &:focus {
    outline: none;
  }
}
